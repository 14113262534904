export class DataTransform {

  public static findObjInArrayByKeyAndReplaceIfKeyChanged(array: any[] | null, obj: any, key: any, keyCheck: any) {
    if (array === null || array.length === 0) {
      return false;
    }
    const newArray = array.slice();
    const foundIndex = array.findIndex(x => x[key] === obj[key]);
    if (newArray[foundIndex][keyCheck] !== obj[keyCheck]) {
      newArray[foundIndex] = obj;
      return newArray;
    } else {
      return false;
    }
  }

  public static transfromGrids(grids) {
    const newGrids = Object.assign({}, grids);
    const newEntities = newGrids.entities.slice();
    newEntities.forEach((item, i) => {
      if (newEntities[i].type === 'destination') {
        newEntities[i] = Object.assign({}, newEntities[i], newEntities[i].input);
      }
    });
    newGrids.entities = newEntities;
    return newGrids;
  }

  public static updateInputStatusAndSLDPLink(store, newData) {
    /* Clone readonly array trick */
    const newArray: any[] = JSON.parse(JSON.stringify(store));
    const foundIndex = store.findIndex(x => x['id'] === newData.id);
    if (foundIndex !== -1) {
      if (!(newData.operation === 'delete' && newData.status === 'active')) {
        newArray[foundIndex] = {...newArray[foundIndex], status: newData.status};
      }
    }

    if (newData.status === 'active' && foundIndex !== -1) {
      if (newData?.input?._id) {
        const foundClusterIdIndex = newArray[foundIndex].clusters.findIndex(x => x['id'] === newData.cluster._id);
        const foundInstanceIdIndex = newArray[foundIndex]
          .clusters[foundClusterIdIndex]
          .instances
          .findIndex(x => x['id'] === newData.instance.id);
        newArray[foundIndex].clusters[foundClusterIdIndex].instances[foundInstanceIdIndex].sldp = {
          ...newArray[foundIndex].clusters[foundClusterIdIndex].instances[foundInstanceIdIndex].sldp,
          sldpLink: newData?.sldpLink,
          sldpTranscodedLink: newData?.sldpTranscodedLink,
        };
      }
    }
    return newArray;
  }
}
